@layer base {
  .hdr-DropdownCommunity {
  display: inline-block;
  position: relative;
  outline: 0;
  cursor: pointer;
}

.hdr-DropdownCommunity--mobile {
  position: static;
}

.hdr-DropdownCommunity--header {
  width: 100%;
}

.hdr-DropdownCommunity__content {
  display: none;
  visibility: hidden;
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 80%;
  right: 0;
  box-sizing: border-box;
  min-width: 270px;
  padding: 0 1.875rem 1.875rem;
  transition: visibility 0.5s;
  opacity: 0;
  pointer-events: auto;
}

.hdr-DropdownCommunity:hover .hdr-DropdownCommunity__content,
.hdr-DropdownCommunity__content:hover {
  display: block;
  visibility: visible;
  position: static;
  opacity: 1;
  background-color: #ffffff;
  box-shadow: var(--shadow-default);
}

.hdr-DropdownCommunity:hover .hdr-DropdownCommunity__content--mobile {
  position: absolute;
  top: 105%;
  width: 98%;
  margin: 0 1%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.hdr-DropdownCommunity__item {
  list-style-type: none;
}

/** Dropdown link content */

.hdr-DropdownCommunity__link {
  display: flex;
  align-items: center;
  color: var(--themeButtonTextColor);
  text-decoration: none;
}

.hdr-DropdownCommunity__link:hover {
  background-color: var(--sk-color-grey-025);
  color: var(--themeButtonTextColor);
}

.hdr-DropdownCommunity__link::after {
  background-color: var(--themeButtonTextColor);
}

.hdr-DropdownCommunity__linkTextWrapper {
  margin: 0;
}

.hdr-DropdownCommunity__linkTitle {
  color: var(--themeMainTextColor);
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.hdr-DropdownCommunity--nav {
  display: flex;
  align-items: center;
}

.hdr-DropdownCommunity--nav svg {
  padding-left: var(--sk-space-4);
}

@media (width < 48rem) {
  /* --small-and-less-only */
  .hdr-DropdownCommunity--nav > svg {
    display: none;
  }
}

@media (width >= 64rem) {
  /* --large-viewport */
  .hdr-DropdownCommunity--mobile {
    position: relative;
  }

  .hdr-DropdownCommunity--header {
    width: inherit;
  }

  .hdr-DropdownCommunity__content {
    display: block;
    min-width: 345px;
    padding: 0;
    cursor: initial;
  }

  .hdr-DropdownCommunity__content--left {
    right: inherit;
    left: 0;
  }

  .DropdownCommunity__list {
    padding: 8px;
  }

  .hdr-DropdownCommunity:hover .hdr-DropdownCommunity__content {
    position: absolute;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 20%);
  }

  .hdr-DropdownCommunity:hover .hdr-DropdownCommunity__content--mobile {
    position: absolute;
    top: 80%;
    width: inherit;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .hdr-DropdownCommunity__link {
    padding: 8px;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .hdr-DropdownCommunity__content {
  border-radius: var(--sk-radius-8);
}

[data-theme="jobteaser-uishift"] .hdr-DropdownCommunity__linkTitle {
  color: var(--sk-color-black);
}

}