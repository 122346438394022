@layer base {
  .main {
  padding: calc(var(--sk-space-32) / 2);
  border-bottom: 1px solid #dbdbdb;
}

.menuItem__desktop {
  display: none;
}

@media (width < 64rem) {
  /* --medium-and-less-only */
  .menuItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .menuItem__desktop {
    display: none;
  }
}

@media (width >= 64rem) {
  /* --large-viewport */
  .main {
    display: flex;
    padding: var(--sk-space-48) 0;
    font-size: 0.875rem;
  }

  .menuItem {
    flex: 1 0 auto;
  }

  .menuItem__desktop {
    display: block;
  }
}

}