@layer base {
  .hdr-DropdownMenu {
  font-size: 1rem;
}

.hdr-DropdownMenu-item {
  display: flex;
  position: relative;
  outline: none;
}

@media (--sk-small-viewport-only) {
  .hdr-DropdownMenu-item {
    justify-content: center;
  }
}

.hdr-DropdownMenu-item:focus-within {
  pointer-events: none;
}

.hdr-DropdownMenu-link {
  display: flex;
  align-items: center;
  padding: var(--sk-space-12);
  border: none;
  background-color: transparent;
  font-family: var(--fontFamily);
  white-space: nowrap;
}

.hdr-DropdownMenu-link::after,
.hdr-DropdownMenu-link .hdr-DropdownMenu-arrowIcon {
  margin: 0.125rem 0 0 0.5rem;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight-regular);
  font-feature-settings: "liga";
  text-rendering: optimizelegibility;
  word-wrap: normal;
  vertical-align: middle;
}

.hdr-DropdownMenu-submenu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  box-shadow: var(--shadow-default);
  list-style: none;
  pointer-events: auto;
}

.hdr-DropdownMenu-item:focus-within .hdr-DropdownMenu-submenu {
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  height: auto;
  padding: 8px;
  clip: auto;
  background: var(--sk-color-white);
}

.hdr-DropdownMenu-submenuLink {
  display: flex;
  align-items: center;
  padding: 8px;
  text-decoration: none;
}

.hdr-DropdownMenu-submenuLink:hover {
  background-color: var(--sk-color-grey-025);
}

.hdr-DropdownMenu-linkIcon {
  display: flex;
  align-items: center;
}

.hdr-DropdownMenu-link--flagOnBottom {
  padding: 0.8125rem 0.75rem;
  border-radius: var(--sk-radius-4);
  background-color: var(--sk-color-white);
  color: var(--sk-color-grey-500);
  font-size: 0.875rem;
  font-weight: var(--font-weight-semibold);
}

.hdr-DropdownMenu-link--flagOnBottom .hdr-DropdownMenu-linkIcon {
  padding-right: 0.5rem;
}

.hdr-DropdownMenu-item--flag .hdr-DropdownMenu-submenuLinkText {
  margin-left: 12px;
  color: var(--sk-color-grey-600);
  font-size: 0.75rem;
  font-weight: var(--font-weight-regular);
  line-height: 1.25rem;
}

.hdr-DropdownMenu-item--flag .hdr-DropdownMenu-submenuLinkItem {
  display: flex;
  align-items: center;
  margin: 0;
}

.hdr-DropdownMenu-item--flag .hdr-DropdownMenu-submenuItem:focus-within,
.hdr-DropdownMenu-item--flag .hdr-DropdownMenu-submenuItem--selected {
  background-color: var(--sk-color-grey-025);
}

@media (width < 64rem) {
  /* --medium-and-less-only */
  .hdr-DropdownMenu-item--flagOnTop {
    width: 100%;
  }

  .hdr-DropdownMenu-item:focus-within .hdr-DropdownMenu-submenu {
    left: 30px;
    width: calc(100% - 30px * 2);
  }

  .hdr-DropdownMenu-link--flagOnTop {
    width: 100%;
    padding: 1.125rem 1.875rem;
    border: thin solid var(--sk-color-grey-200);
    border-radius: var(--sk-radius-4);
  }

  .hdr-DropdownMenu-link--flagOnTop .hdr-DropdownMenu-linkIcon {
    padding-right: 0.5rem;
  }

  .hdr-DropdownMenu-link--flagOnTop .hdr-DropdownMenu-linkLabel,
  .hdr-DropdownMenu-link--flagOnBottom .hdr-DropdownMenu-linkLabel {
    flex: 1;
    font-weight: var(--font-weight-semibold);
  }

  .hdr-DropdownMenu-item--flag:focus-within .hdr-DropdownMenu-submenu {
    top: -300px;
    left: 0;
    width: 100%;
    height: 300px;
    padding: 0;
    overflow-y: auto;
    border: thin solid var(--sk-color-grey-100);
    box-shadow: var(--shadow-default);
  }

  .hdr-DropdownMenu-item--flagOnBottom:focus-within .hdr-DropdownMenu-submenu {
    top: inherit;
    bottom: calc(100% + 1px);
    height: auto;
    padding: 8px;
  }

  .hdr-DropdownMenu-item--flag .hdr-DropdownMenu-submenuItem {
    border-bottom: thin solid var(--sk-color-grey-100);
  }

  .hdr-DropdownMenu-item--flagOnBottom:focus-within .hdr-DropdownMenu-submenuItem {
    border: 0;
  }

  .hdr-DropdownMenu-item--flag .hdr-DropdownMenu-submenuLinkItem {
    padding: 16px 20px;
  }
}

@media (width >= 64rem) {
  /* --large-viewport */
  .hdr-DropdownMenu {
    display: flex;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-weight: var(--font-weight-semibold);
  }

  .hdr-DropdownMenu-item:focus-within .hdr-DropdownMenu-submenu {
    box-sizing: border-box;
    width: 345px;
    max-height: 50vh;
    overflow-y: visible;
  }

  .hdr-DropdownMenu-link--flagOnTop .hdr-DropdownMenu-linkLabel {
    display: none;
  }

  .hdr-DropdownMenu-link--flagOnTop .hdr-DropdownMenu-linkIcon {
    padding-right: 0;
  }

  .hdr-DropdownMenu-item--flag:focus-within .hdr-DropdownMenu-submenu {
    width: inherit;
  }

  .hdr-DropdownMenu-item--flagOnTop:focus-within .hdr-DropdownMenu-submenu,
  .hdr-DropdownMenu-item--flagOnTop .hdr-DropdownMenu-submenuLinkItem {
    padding: 8px;
  }

  .hdr-DropdownMenu-item--flagOnBottom:focus-within .hdr-DropdownMenu-submenu {
    display: flex;
    top: inherit;
    right: 0;
    bottom: calc(100% + 1px);
    flex-wrap: wrap;
    width: auto;
    padding: 8px;
    border: thin solid var(--sk-color-grey-100);
    border-radius: var(--sk-radius-4);
  }

  .hdr-DropdownMenu-item--flagOnBottom:focus-within .hdr-DropdownMenu-submenuItem {
    box-sizing: border-box;
    width: 100%;
  }

  .hdr-DropdownMenu-item--flagOnBottom:focus-within .hdr-DropdownMenu-submenuLink {
    padding: 12px 8px;
  }

  .hdr-DropdownMenu-linkIcon {
    display: flex;
    padding-right: 0.5rem;
  }

  .hdr-DropdownMenu-link {
    cursor: pointer;
  }

  .hdr-DropdownMenu-item:focus-within .hdr-DropdownMenu-link::after,
  .hdr-DropdownMenu-item:focus-within .hdr-DropdownMenu-link .hdr-DropdownMenu-arrowIcon {
    transform: rotate(180deg);
    transition: transform 0.1s ease;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-link {
  color: var(--sk-color-black);
}

[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-link--active {
  color: var(--color-primary);
}

[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-link--active::after {
  background-color: var(--color-primary);
}

[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-submenu,
[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-link--flagOnBottom,
[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-submenuLink,
[data-theme="jobteaser-uishift"] .hdr-DropdownMenu-item--flag .hdr-DropdownMenu-submenuItem--selected {
  border-radius: var(--sk-radius-8);
}

@media (width < 64rem) {
  /* --medium-and-less-only */
  [data-theme="jobteaser-uishift"] .hdr-DropdownMenu-link--flagOnTop {
    border-radius: var(--sk-radius-8);
  }
}

@media (width >= 64rem) {
  /* --large-viewport */
  [data-theme="jobteaser-uishift"] .hdr-DropdownMenu-item--flagOnBottom:focus-within .hdr-DropdownMenu-submenu {
    border-radius: var(--sk-radius-8);
  }
}

}