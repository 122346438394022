@layer base {
  .main {
  display: flex;
  align-items: center;
  padding: var(--sk-space-12);
  border: none;
  background-color: transparent; /* button reset */
  color: var(--sk-color-black);
  font-family: var(--fontFamily);
  font-size: var(--sk-font-size-1); /* 14px */
  line-height: var(--sk-line-heights-5); /* 16px */
  white-space: nowrap;
  cursor: pointer;
}

/* The login button is visible when the nav is displayed in the burger menu (mobile/tablet) */
.main___inNav {
  width: 100%;
  margin: 0 var(--sk-space-12);
}

@media (width >= 64rem) {
  /* --large-viewport */
  /* The login button is hidden when the nav is displayed in the website header (desktop) */
  .main___inNav {
    display: none;
  }
}

.icon {
  display: flex;
  align-items: center;
}

.label {
  margin-left: 0.5rem;
}

.label___visibleOnDesktopOnly {
  /* screen reader only */
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  border: 0;
  white-space: nowrap;
}

@media (--large-viewport) {
  /* disable screen reader only */
  .label___visibleOnDesktopOnly {
    position: initial;
    width: initial;
    height: initial;
    padding: initial;
    overflow: initial;
    clip: initial;
    clip-path: initial;
    border: initial;
    white-space: initial;
  }
}

}