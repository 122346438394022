@layer base {
  .title {
  margin-top: 0;
  margin-bottom: calc(var(--sk-space-32) / 2);
  font-size: 0.875rem;
  font-weight: var(--font-weight-semibold);
}

.link {
  display: inline-block;
  padding: var(--sk-space-16);
  color: var(--sk-color-white);
  font-size: var(--typo-body-3-font-size);
  line-height: var(--typo-body-2-line-height);
  text-decoration: none;
}

@media (width < 48rem) {
  /* --small-and-less-only */
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (width < 64rem) {
  /* --medium-and-less-only */
  .main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .item {
    padding: 0 16px;
  }

  .title {
    display: none;
  }
}

@media (width >= 64rem) {
  /* --large-viewport */
  .title::after {
    content: "";
    display: block;
    width: var(--sk-space-24);
    margin-bottom: var(--sk-space-24);
    padding-bottom: var(--sk-space-24);
    border-bottom: thin solid var(--sk-color-white);
  }

  .link {
    padding: 0;
  }
}

}