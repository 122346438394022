@layer base {
  .main {
  --mj-breakpoint: 80rem;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;
  --typo-body-2-font-size: 16px;
  --typo-body-2-line-height: 24px;
  --typo-body-3-font-size: 14px;
  --typo-body-3-line-height: 24px;
  margin-top: auto;
  background-color: var(--themeHeaderTextColor, var(--sk-color-black));
  color: var(--sk-color-white);
}

.main ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.brand {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: calc(var(--sk-space-32) / 2) 0;
  border-bottom: 0.0625em solid #dbdbdb;
}

.brand svg path {
  fill: var(--sk-color-white);
}

.locales {
  margin: 1rem 0;
  text-align: center;
}

@media (width < 48rem) {
  /* --small-and-less-only */
  .logo {
    height: 1.5rem;
  }

  .logo__smallScreenBottomMargin {
    margin-bottom: var(--sk-space-16);
  }
}

@media (width >= 48rem) {
  /* --medium-viewport */
  .container {
    --layout-size: 100%;
    box-sizing: border-box;
    width: 100%;
    max-width: var(--layout-size);
    margin: auto;
    padding: 0 var(--sk-space-32);
  }

  .brand {
    flex-direction: row;
  }

  .brandContainer {
    display: flex;
  }

  .locales {
    margin: 0 1rem 0 0;
  }
}

@media (width >= 64rem) {
  /* --large-viewport */
  .container {
    --layout-size: var(--mj-breakpoint);
    padding: 0 var(--sk-space-24);
  }

  .brand {
    padding: var(--sk-space-48) 0;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .main {
  background-color: var(--sk-color-black);
}

/* the footer is NOT displayed on the printed version of the page */

@media print {
  .main {
    display: none;
  }
}

}